import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import { Header, height as headerHeight } from "../components/Header"
import { Layout } from "../components/layout"
import EmailLink from "../components/EmailLink"
import { Section, smallVPadding, largeVPadding } from "../components/Section"
import { Container } from "../components/Container"
import { FluidBackgroundImage } from "../components/FluidBackgroundImage"
import { P } from "../components/text/P"
import { Content } from "../components/text/Content"

const CenteredContent = styled(Content)`
  margin-left: auto;
  margin-right: auto;
`

const CenteredP = styled(P)`
  text-align: center;
`

const H1White = styled.h1`
  text-align: center;
  color: white;
`

const headerTitleEstimatedHeight = 140

const CareerSection = styled(Section)`
  min-height: calc(
    100vh - ${headerHeight + smallVPadding * 2 + headerTitleEstimatedHeight}px
  );
  @media all and (min-width: 960px) {
    min-height: calc(
      100vh - ${headerHeight + largeVPadding * 2 + headerTitleEstimatedHeight}px
    );
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

const CareerContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

const PositionList = styled.div`
  margin-bottom: 48px;
`

const PositionItemTitle = styled.h3`
  margin-bottom: 0;
`

const PositionItemDesc = styled.p`
  margin-top: 0;
`

const LinkStyled = styled(Link)`
  text-decoration: underline;
  font-weight: 400;
  :link,
  :visited {
    color: rgba(0, 0, 0, 0.65);
    filter: brightness(1);
    transition: all 0.3s ease-in;
  }
  :hover,
  :focus,
  :active {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
    filter: brightness(0.7);
    transition: all 0.3s ease-out;
  }
`

const EmailUs = styled.div`
  text-align: center;
`

const CareersPage = (props) => {
  const page = props.data.allDataJson.edges[0].node.pages.careers
  const positions = props.data.allMarkdownRemark.edges
  const renderPositions = (positions) => {
    return positions.map((pos) => (
      <div key={pos.node.frontmatter.path}>
        <PositionItemTitle>
          <LinkStyled to={pos.node.frontmatter.path}>{`${
            pos.node.frontmatter.title
          }`}</LinkStyled>
        </PositionItemTitle>
        <PositionItemDesc>{pos.node.frontmatter.availability}</PositionItemDesc>
      </div>
    ))
  }
  return (
    <Layout meta={page.meta} loadUikit={true}>
      <Header />
      <FluidBackgroundImage fluid={page.title_bg.childImageSharp.fluid}>
        <Section>
          <Container>
            <CenteredContent maxWidth={600}>
              <H1White>{page.title}</H1White>
              <CenteredP data={page.subtitle} />
            </CenteredContent>
          </Container>
        </Section>
      </FluidBackgroundImage>
      <CareerSection>
        <CareerContainer>
          <PositionList>
            {positions && positions.length
              ? renderPositions(positions)
              : "Currently there is no position advertised."}
          </PositionList>
          <EmailUs>
            {page.email_us.description}
            <br />
            <EmailLink
              image={page.email_us.image.childImageSharp.fixed}
              emailAddEncoded={page.email_us.emailAddEncoded}
            />
          </EmailUs>
        </CareerContainer>
      </CareerSection>
    </Layout>
  )
}

export default CareersPage

export const pageQuery = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            careers {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              title
              subtitle
              title_bg {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              email_us {
                description
                image {
                  childImageSharp {
                    fixed(width: 121, height: 16) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                emailAddEncoded
              }
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            path
            title
            availability
          }
        }
      }
    }
  }
`
